<template>
  <div class="projeto">
    <Nav />
    <Icon />
    <div class="grid-box">
      <Breadcrumb :levels="breadcrumbLevels" />
      <div class="main-block" :class="pageOptions.FooterClass">
        <router-view
          :page-options="pageOptions"
          @changeLevels="changeLevels"
        />
      </div>
    </div>
  </div>
</template>
<script type="plain/text">
import Nav from "@/components/Nav.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Icon from "@/components/Icon.vue";
import PageOptions from "@/scripts/models/pageOptions.model";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import ProjectService from "@/scripts/services/project.service.js";
export default {
  props: ["projectId"],
  data() {
    return {
      pageOptions: new PageOptions(),
      projectService: new ProjectService(),
      pages: [
        Routes.app.ProjectStep0,
        Routes.app.ProjectStep1,
        Routes.app.ProjectStep2,
        Routes.app.ProjectStep3,
        Routes.app.ProjectStep4,
        Routes.app.ProjectStep50,
        Routes.app.ProjectStep51
      ],
      breadcrumbLevels: [{ text: "Projetos de Voluntariado" }]
    };
  },
  created(){
    this.pageOptions.changeClass("footer");
  },
  components: {
    Nav,
    Breadcrumb
  },
  methods: {
    changeLevels(levels) {
      this.breadcrumbLevels = levels;
    }
  },
//   beforeRouteEnter(to, from, next) {
//     if (!to.query.projectId) {
//       if (
//         (to.name == Routes.app.ProjectGallery ||
//           to.name == Routes.app.ProjectDocuments) &&
//         to.name != Routes.app.ProjectList
//       ) {
//         next({ name: Routes.app.ProjectList });
//       } else if (
//         to.name != Routes.app.ProjectList &&
//         to.name != Routes.app.ProjectStep0
//       ) {
//         next({ name: Routes.app.ProjectStep0 });
//       } else {
//         next();
//       }
//     } else {
//       next();
//     }
//   }
};
</script>